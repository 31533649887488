<template>
  <div>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Kode</label>
              <InputText
                v-model="form.kode"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Nama</label>
              <InputText
                v-model="form.nama"
                :class="{ 'p-invalid': v$.form.nama.$invalid && submitted }"
                class="w-full"
                input-class="w-full"
              />
              <small
                v-if="
                  (v$.form.nama.$invalid && submitted) ||
                  v$.form.nama.$pending.$response
                "
                class="p-error"
                >{{ v$.form.nama.required.$message }}</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Simpan"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        kode: {
          required: helpers.withMessage('Kode harus diisi.', required),
        },
        nama: {
          required: helpers.withMessage('Nama harus diisi.', required),
        }
      },
    }
  },
}
</script>

